@import './color-theme.scss';

.MenuItem-WarningColor {
  color: $warning-orange;
}

.MenuItem-ErrorColor {
  color: $error-red;
}

.MenuItem-DoneColor {
  color: $done-color;
}

.default-bar-color {
  background-color: #B7B7B7;
}

.default-bar-sub-color {
  background-color: #E2E2E2;
}

// NEW, MODIFIED, UNCHANGED text with specific highlights
// Do not change text below (Simulation_No, Simulation_Yes, Simulation_New): they are string ids

.state-product-Simulation_Withdrawn {
  padding: 0.2rem;
  margin: 0.1rem;
  border-radius: 0.3rem;

  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9rem;
}

.state-product-Simulation_No {
  padding: 0.2rem;
  margin: 0.1rem;
  border-radius: 0.3rem;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9rem;
}

.state-product-Simulation_Maintained {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.85rem;
}

.state-product-Simulation_Maintained_Product {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.85rem;
}

.state-product-Simulation_Yes {
  background-color: $orange-firm;
  padding: 0.2rem;
  margin: 0.1rem;
  border-radius: 0.3rem;
  color: white;
}

.state-product-Simulation_Started {
  background-color: $app-accent;
  padding: 0.2rem;
  margin: 0.1rem;
  border-radius: 0.3rem;
  color: white;
}

.state-product-Simulation_New {
  text-transform: uppercase;
  background-color: $green-new;
  padding: 0.2rem;
  margin: 0.1rem;
  border-radius: 0.3rem;
  color: white;
}

.state-product-Simulation_Modified {
  text-transform: uppercase;
  background-color: $orange-firm;
  padding: 0.2rem;
  margin: 0.1rem;
  border-radius: 0.3rem;
  color: white;
}


// OTHERS

/**
 * FORM
 **/

mat-form-field.mat-mdc-form-field {
  .mat-mdc-form-field-hint-wrapper {
    padding: inherit;
  }
}

mat-hint.mat-mdc-form-field-hint {
  color: rgba(0, 0, 0, 0.54);
}

label.mdc-floating-label,
label.mdc-text-field--filled {
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-filled-text-field-label-text-tracking: 0;
}

/**
 * BUTTON
 **/

button {
  --mdc-typography-button-letter-spacing: 0;
  --mat-text-button-focus-state-layer-opacity: 0;
}

button.mat-mdc-icon-button svg {
  --mdc-icon-button-icon-size: 28px;
  vertical-align: sub;
}

a.mdc-button {
  --mdc-typography-button-letter-spacing: 0;
}

button .mat-mdc-unelevated-button>.mat-icon,
.mat-mdc-raised-button>.mat-icon,
.mat-mdc-outlined-button>.mat-icon {
  font-size: x-large !important;
  height: auto !important;
  width: auto !important;
  margin: 0;
}

button.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #ffa000; // $app-primary @TODO@NST Verify this !!!
  --mdc-protected-button-label-text-color: #fff;
}

button.mdc-button>.mat-icon,
button.mat-mdc-button>.mat-icon {
  height: 1.5rem;
  width: 1.5rem;
  font-size: 1.5rem;
  margin-right: 0;
}

mat-dialog-container .mat-mdc-button {
  line-height: 2rem;
}

.mat-mdc-raised-button,
.mat-mdc-button {
  letter-spacing: normal !important;
}

/**
 * MAT-SLIDE-TOGGLE
 **/

mat-slide-toggle.mat-mdc-slide-toggle {

  .mdc-label {
    --mat-slide-toggle-label-text-font: Roboto, "Helvetica Neue", sans-serif;
    --mat-slide-toggle-label-text-size: inherit;
    --mat-slide-toggle-label-text-tracking: 0;
    --mat-slide-toggle-label-text-line-height: 24px;
    --mat-slide-toggle-label-text-weight: 500;
  }
}

/**
 * MAT-CHECKBOX
 **/

input,
mat-checkbox,
mat-checkbox.mdc-checkbox {
  --mdc-checkbox-state-layer-size: 24px;

  .mdc-checkbox__background {
    top: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2) !important;
    left: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2) !important;
    padding: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2) !important;
  }

  .mdc-checkbox__background {
    width: 16px !important;
    height: 16px !important;
  }
}

/**
 * MAT-RADIOBUTTON
 **/

mat-radio-button.mat-mdc-radio-button {
  --mdc-radio-state-layer-size: 2rem;

  .mdc-radio {
    --mdc-radio-state-layer-size: 1.75rem;
  }

}

mat-radio-button.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  --mdc-typography-font-family: Roboto, "Helvetica Neue", sans-serif;
  --mdc-typography-body2-font-family: Roboto, "Helvetica Neue", sans-serif;
  --mdc-typography-body2-font-size: 1rem;
  --mdc-typography-body2-line-height: 20px;
  --mdc-typography-body2-font-weight: 400;
  --mdc-typography-body2-letter-spacing: 0;
  --mdc-typography-body2-text-decoration: inherit;
  --mdc-typography-body2-text-transform: none;
}

/**
 * MAT-SELECT
 **/

mat-select.mat-mdc-select {
  --mat-select-trigger-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-select-trigger-text-tracking: 0;
  --mat-select-trigger-text-weight: 400;
}

div.cdk-overlay-connected-position-bounding-box {
  div.cdk-overlay-pane {
    width: auto !important;
  }
}

div.mat-mdc-select-panel {
  padding: 0 !important;
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.mat-mdc-checkbox {
  &.mat-accent {
    --mdc-checkbox-selected-checkmark-color: white;
  }
}

mat-slider.mat-mdc-slider {
  --mdc-slider-active-track-height: 2px;
  --mdc-slider-inactive-track-height: 2px;

  --mdc-slider-handle-width: 16px;
  --mdc-slider-handle-height: 16px;

  --mat-mdc-slider-value-indicator-opacity: 0.8;
  --mdc-slider-label-container-color: rgba(0, 0, 0, 0);
}

mat-slider.mat-mdc-slider.mat-primary {
  --mdc-slider-active-track-height: 4px;
  --mdc-slider-inactive-track-height: 2px;

  --mdc-slider-handle-width: 16px;
  --mdc-slider-handle-height: 16px;
}

mat-slide-toggle.mat-mdc-slide-toggle {

  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}

/**
 * MAT-TOOLBAR
 **/

mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  --mat-toolbar-title-text-tracking: 0;
  --mat-toolbar-title-text-font: Roboto, "Helvetica Neue", sans-serif;
}

/**
 * MAT-TOOLTIP
 **/

mat-tooltip-component {
  white-space: pre-line !important;
}

mat-tooltip-component .mdc-tooltip__surface {
  background-color: $tooltip-background !important;
  color: $tooltip-text-color !important;
  font-size: 0.9rem !important;
  letter-spacing: normal !important;
}

/**
 * MAT-CARD
 **/

mat-card.mat-mdc-card {
  border-radius: unset;
  border-color: unset;
  border-width: 0;
  border-style: none;
}

mat-card.mat-mdc-card-outlined {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  padding: 16px;
  border-radius: 4px;
}

mat-card.mat-mdc-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

/**
 * MAT-DIALOG
 **/

mat-dialog-container.mat-mdc-dialog-container {
  --mdc-dialog-subhead-line-height: 1rem;
  --mdc-dialog-subhead-size: 33px;
  --mdc-dialog-subhead-tracking: 0;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-line-height: 20px;
  --mdc-dialog-supporting-text-size: 1rem;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0;

  --mdc-dialog-subhead-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-dialog-subhead-line-height: 2rem;
  --mdc-dialog-subhead-size: 1.25rem;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}

.mdc-dialog__content>:last-child {
  margin-bottom: 1rem !important;
}

.mdc-dialog .mdc-dialog__content p {
  line-height: 19px !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  --mdc-dialog-subhead-font: 'Roboto, "Helvetica Neue", -serif';
  --mdc-dialog-subhead-line-height: 2rem;
  --mdc-dialog-subhead-size: 1.25rem;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0;
}

h1.mdc-dialog__title {
  margin: 0 0 20px;
  padding: 0;
  height: 2rem;
  line-height: 2rem !important;

  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif !important;
  letter-spacing: normal;
}

h1.mdc-dialog__title::before {
  display: none;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}

.mdc-dialog__actions {
  padding: 0 !important;
  padding-top: 8px;
}

/**
 * MAT-NAVLIST
 **/

mat-nav-list.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

/**
 * MAT-FORM-FIELD
 **/

mat-form-field {
  --mdc-filled-text-field-container-color: transparent;

  --mat-form-field-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-form-field-container-text-line-height: 1.125;
  --mat-form-field-container-text-size: inherit;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;

  --mat-form-field-subscript-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.033333333333333em;
  --mat-form-field-subscript-text-weight: 400;

  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;

  .mat-mdc-form-field-focs-overlay {
    background-color: rgba(0, 0, 0, 0);
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 24px;
    padding-bottom: 0;
  }

  .mat-mdc-form-field-infix {
    min-height: 36px;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: white;
  }

  .mdc-text-field {
    padding: 0;
  }

  .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-130%) scale(0.75);
  }
}

/**
 * TABLE MAT-TABLE
 **/

table {
  --mat-table-header-headline-size: 12px;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.54);
  --mat-table-header-headline-font: Roboto, "Helvetica Neue", sans-serif;

  --mat-table-row-item-container-height: 48px;
  --mat-table-row-item-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-table-row-item-label-text-line-height: 17px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 500;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-row-item-label-text-tracking: normal;

  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    padding: 0;
  }

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-left: 24px;
  }

  th.mat-mdc-header-cell:last-of-type,
  td.mat-mdc-cell:last-of-type,
  td.mat-mdc-footer-cell:last-of-type {
    padding-right: 24px;
  }
}

mat-tab-header.mat-mdc-tab-header {
  --mat-tab-header-active-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-tab-header-label-text-font: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
  --mat-tab-header-label-text-size: 18px;
  --mat-tab-header-label-text-tracking: 0;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 400;

  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-tab-header-active-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-tab-header-active-ripple-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-ripple-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);

  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0 !important;
  min-width: 10rem;
}

mat-step-header {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}

div.filter-container {
  top: calc(-3rem - 1px);

  mat-form-field {
    .mdc-text-field--filled .mdc-floating-label {
      --mdc-filled-text-field-label-text-font: Roboto, "Helvetica Neue", sans-serif;
      --mdc-filled-text-field-label-text-size: 14px;
      --mdc-filled-text-field-label-text-weight: 400;
      --mdc-filled-text-field-label-text-tracking: normal;
    }

    .mat-mdc-select {
      --mat-select-trigger-text-font: 14px;
      font-size: 14px;
    }
  }
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-weight: 400;
}

a.mdc-list-item {
  padding-left: 0;
  padding-right: 0;
}
