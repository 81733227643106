@font-face{
  font-family:Material Icons;
  font-style:normal;
  font-weight:400;
  font-display:block;
  src:url(/assets/material/material-icons.59322316b3fd6063.woff2) format("woff2"),url(/assets/material/material-icons.4ad034d2c499d9b6.woff) format("woff")
}

.material-icons{
  font-family:Material Icons;
  font-weight:400;
  font-style:normal;
  font-size:24px;
  line-height:1;
  letter-spacing:normal;
  text-transform:none;
  display:inline-block;
  white-space:nowrap;
  word-wrap:normal;
  direction:ltr;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  text-rendering:optimizeLegibility;
  font-feature-settings:"liga"
}

@font-face{
  font-family:Material Icons Outlined;
  font-style:normal;
  font-weight:400;
  font-display:block;
  src:url(/assets/material/material-icons-outlined.f86cb7b0aa53f0fe.woff2) format("woff2"),url(/assets/material/material-icons-outlined.78a93b2079680a08.woff) format("woff")
}

.material-icons-outlined{
  font-family:Material Icons Outlined;
  font-weight:400;
  font-style:normal;
  font-size:24px;
  line-height:1;
  letter-spacing:normal;
  text-transform:none;
  display:inline-block;
  white-space:nowrap;
  word-wrap:normal;
  direction:ltr;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  text-rendering:optimizeLegibility;
  font-feature-settings:"liga"
}

@font-face{
  font-family:Material Icons Round;
  font-style:normal;
  font-weight:400;
  font-display:block;
  src:url(/assets/material/material-icons-round.b10ec9db5b7fbc74.woff2) format("woff2"),url(/assets/material/material-icons-round.92dc7ca2f4c591e7.woff) format("woff")
}

.material-icons-round{
  font-family:Material Icons Round;font-weight:400;
  font-style:normal;
  font-size:24px;
  line-height:1;
  letter-spacing:normal;
  text-transform:none;
  display:inline-block;
  white-space:nowrap;
  word-wrap:normal;
  direction:ltr;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  text-rendering:optimizeLegibility;
  font-feature-settings:"liga"
}

@font-face{
  font-family:Material Icons Sharp;
  font-style:normal;
  font-weight:400;
  font-display:block;
  src:url(/assets/material/material-icons-sharp.3885863ee4746422.woff2) format("woff2"),url(/assets/material/material-icons-sharp.a71cb2bf66c604de.woff) format("woff")
}

.material-icons-sharp{
  font-family:Material Icons Sharp;
  font-weight:400;
  font-style:normal;
  font-size:24px;
  line-height:1;
  letter-spacing:normal;
  text-transform:none;
  display:inline-block;
  white-space:nowrap;
  word-wrap:normal;
  direction:ltr;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  text-rendering:optimizeLegibility;
  font-feature-settings:"liga"
}

@font-face{
  font-family:Material Icons Two Tone;
  font-style:normal;
  font-weight:400;
  font-display:block;
  src:url(/assets/material/material-icons-two-tone.675bd578bd14533e.woff2) format("woff2"),url(/assets/material/material-icons-two-tone.588d63134de807a7.woff) format("woff")
}

.material-icons-two-tone{
  font-family:Material Icons Two Tone;
  font-weight:400;
  font-style:normal;
  font-size:24px;
  line-height:1;
  letter-spacing:normal;
  text-transform:none;
  display:inline-block;
  white-space:nowrap;
  word-wrap:normal;
  direction:ltr;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  text-rendering:optimizeLegibility;
  font-feature-settings:"liga"
}