/* You can add global styles to this file, and also import other style files */
//@import 'material-icons/iconfont/material-icons.scss';
@import 'assets/material/material.scss'; // Static way (uses files in src/assets/material/). Uncomment the line above to add as library (and comment this line)
@import 'assets/fonts/roboto/family.scss';

html, body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.todo {
    font-size: xx-large;
    animation: color-change 1s infinite;
    margin-left: 2rem;

    @keyframes color-change {
        0% { color: red; }
        50% { color: darkred; }
        100% { color: red; }
      }
}

@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
.fade-in {
    opacity:0;
    -webkit-animation:fadeIn ease-in 1;
    -moz-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;

    -webkit-animation-fill-mode:forwards;
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;

    -webkit-animation-duration:.2s;
    -moz-animation-duration:.2s;
    animation-duration:.2s;
}

@-webkit-keyframes delayIn1 { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes delayIn1 { from { opacity:0; } to { opacity:1; } }
@keyframes delayIn1 { from { opacity:0; } to { opacity:1; } }
.delay-in1 {
    opacity:0;
    -webkit-animation:fadeIn ease-in 1;
    -moz-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;

    -webkit-animation-fill-mode:forwards;
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;

    -webkit-animation-duration:0.2s;
    -moz-animation-duration:0.2s;
    animation-duration:0.2s;

    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

@-webkit-keyframes delayIn2 { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes delayIn2 { from { opacity:0; } to { opacity:1; } }
@keyframes delayIn2 { from { opacity:0; } to { opacity:1; } }
.delay-in2 {
    opacity:0;
    -webkit-animation:fadeIn ease-in 1;
    -moz-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;

    -webkit-animation-fill-mode:forwards;
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;

    -webkit-animation-duration:0.2s;
    -moz-animation-duration:0.2s;
    animation-duration:0.2s;

    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
    animation-delay: 2s;
}

@-webkit-keyframes delayIn3 { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes delayIn3 { from { opacity:0; } to { opacity:1; } }
@keyframes delayIn3 { from { opacity:0; } to { opacity:1; } }
.delay-in3 {
    opacity:0;
    -webkit-animation:fadeIn ease-in 1;
    -moz-animation:fadeIn ease-in 1;
    animation:fadeIn ease-in 1;

    -webkit-animation-fill-mode:forwards;
    -moz-animation-fill-mode:forwards;
    animation-fill-mode:forwards;

    -webkit-animation-duration:0.2s;
    -moz-animation-duration:0.2s;
    animation-duration:0.2s;

    -webkit-animation-delay: 20s;
    -moz-animation-delay: 20s;
    animation-delay: 20s;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

table.beautify {
  th, td {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  th.cdk-column-delete,
  td.cdk-column-delete,
  th.cdk-column-mail,
  td.cdk-column-mail {
    padding-left: 0;
    padding-right: 0;
  }

  th.cdk-column-edit,
  td.cdk-column-edit,
  th.cdk-column-users,
  td.cdk-column-users,
  th.cdk-column-teams,
  td.cdk-column-teams {
    padding-left: 0;
  }
}

app-scrollbar>.ps {
  display: block;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  position: static;
  width: 100%;
}
