$app-accent: #9E9E9E;
$app-warn-color: #FF5722;

$maintenance-color: #C91E5D;

$splashscreen-background: #FFF8E1; // = $mat-amber, 50
$splashscreen-text: rgb(99,99,99);

$hidden-indicator-color: rgb(224, 0, 0);

$done-color: rgb(0, 192, 0);

$warning-orange: rgba(255, 117, 0, 1);
$error-red: rgba(255, 0, 0, 1);
$ok-green:rgb(0, 192, 0);

$new-color: rgb(0, 192, 0);

$disabled-grey: grey;
$disabled-grey-light: rgb(218,218,218);

$info-icon-grey: rgb(218,218,218);
$info-icon-grey-darker: rgb(200,200,200);
$info-popup-grey: rgb(226,226,226);

$tooltip-background: rgb(226,226,226);
$tooltip-text-color: rgb(99,99,99);

$table-top-row-background: transparent;
$table-odd-row-background: rgb(248,248,248);
$table-even-row-background: rgb(238,238,238);
$table-selected-row-background: rgba(0,0,0,0.2);

$topbanner-submit:rgb(0, 192, 0);
$impersonate-red: red;
$grey-title: rgba(0,0,0,0.66);

$red-firm: rgba(184, 40, 50, 1);
// $blue-firm: rgba(0, 118, 163, 1);
$orange-firm: rgba(235, 97, 25, 1);
// $purple-firm: rgba(115, 3, 128, 1);
// $green-firm: rgba(17, 128, 17, 1);
// $yellow-firm: rgba(241, 198, 47, 1);


$green-new: rgba(100, 181, 50, 1);


$background-palette: #F5F5F5;
$background-medium-palette: #E0E0E0;
$background-dark-palette: #757575;


$take-decision-state: rgb(0, 192, 0);       // DecisionPeriod
$view-decision-state:  orange;              // Initialized, Pause
$synchronisation-state: rgb(56, 128, 255);  // Ready For Run
$processing-state: rgb(117, 117, 117);      // Ready For Run


$link-color: #1e79a7;
$link-color-hover: #FF0084;

$container-background-color: #fafafa;

$color-time-over: rgba(0, 0, 0, 0.38);  // dashboard
